.cc-date-picker {
    .react-datepicker {
        $this: &;

        width: 100%;
        border-radius: 0;
        border: none;
        font-family: $ff-primary;
    }

    .react-datepicker__header {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem 0;
        padding: 0;
        border-radius: 0;
        border: none;
        background-color: unset;
    }

    .react-datepicker__navigation {
        top: 0;
        height: auto;

        &--previous {
            left: unset;
            right: 2rem;
        }

        &--next {
            right: 5px;
            justify-content: flex-end;
            overflow: visible;
        }

        &-icon {
            top: 0;
            font-size: unset;
            width: auto;

            &::before {
                width: 7px;
                height: 7px;
                border-color: $co-grey-1;
                border-width: 1px 1px 0 0;
            }
        }
    }

    .react-datepicker__current-month {
        flex: 1 0 100%;
        padding-left: 5px;
        color: $co-grey-1;
        font-size: $display-9;
        font-weight: 600;
        text-transform: capitalize;
        text-align: left;
    }

    .react-datepicker__month-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        float: none;
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__week,
    .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        flex: auto;
    }

    .react-datepicker__day-name {
        color: $co-grey-3;
        font-size: $display-9;
        font-weight: 400;
        text-transform: uppercase;
    }

    .react-datepicker__day {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        font-size: $display-9;
    }

    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ),
    .react-datepicker__month-text--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ),
    .react-datepicker__quarter-text--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ),
    .react-datepicker__year-text--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ) {
            background-color: unset;
            user-select: none;

        &:hover {
            background-color: $co-primary;
            color: $co-lighter;
        }
    }
    .react-datepicker__day--today {
        background-color: unset;
        color: $co-primary;
        &:hover {
            background-color: $co-primary-light;
        }
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
        background-color: $co-primary;
        color: $co-lighter;
        user-select: none;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 0;
        border-top: solid 1px $co-primary;
        border-bottom: solid 1px $co-primary;
        background-color: unset;
        color: $co-grey-1;
        user-select: none;
    }
    
    .react-datepicker__day--range-start {
        border-left: solid 1px $co-primary;
        border-radius: 10px 0 0 10px;
    }
    .react-datepicker__day--range-end {
        border-right: solid 1px $co-primary;
        border-radius: 0 10px 10px 0;
    }
    .react-datepicker__day--range-start.react-datepicker__day--range-end {
        border-radius: 10px;
    }

    &__help {
        display: flex;
        gap: 4px;
        padding: 5px 10px 5px 5px;
        margin: 10px 0 0 0;
        border-radius: 4px;
        background: $co-grey-6;
        font-size: $display-10;
    }

}
